import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

export default function Messages(props) {
  const { children, botColor, userColor, botText, userText } = props;
  const Clear = styled.div`
    clear: both;
  `;
  const MessageWrapper = styled.ul`
    overflow: auto;
    display: flex;
    flex-direction: column;
    list-style: none;
  `;

  return (
    <MessageWrapper botColor={botColor} userColor={userColor} botText={botText} userText={userText}>
      {children.map(child => {
        const copyChild = {...child};
        const copyChildProps = {...copyChild.props, userColor, botColor}
        
        copyChild.props = copyChildProps;

        return copyChild;
      })}
      <Clear />
    </MessageWrapper>
  );
}

Messages.propTypes = {
  children: propTypes.any,
};
