import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

export default function InputFlex(props) {
  const { children, onlyInputs } = props;

  const InputFlexStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${props =>
      onlyInputs === true
        ? 'input { width: 100%; &:not(:last-of-type) { margin-right: 1em; } }'
        : ' input { width: 70%; } button { width: 30%; }'}

    @media (min-width: 426px) {
      ${props => (!onlyInputs ? 'button { margin-left: 1em; }' : null)}
    }

    @media (max-width: 425px) {
      flex-direction: column;
      input,
      button {
        width: 100%;
      }
    }
  `;

  return <InputFlexStyle>{children}</InputFlexStyle>;
}

InputFlex.propTypes = {
  children: propTypes.any,
  onlyInputs: propTypes.bool,
};
