import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const ListItemStyle = styled.li`
  background-color: #f9fafb;
  color: #606060;
  transition: ease-in 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #ededed;
    transition: ease-out 0.3s;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #eeeeee;
  }
`;

export default function ListItem(props) {
  const { children, icon } = props;
  const IconStyle = styled.span`
    ${props => (icon ? 'margin-right: .6em;' : null)}
  `;

  return (
    <ListItemStyle>
      {icon ? <IconStyle>{icon}</IconStyle> : null}
      {children}
    </ListItemStyle>
  );
}

ListItem.propTypes = {
  children: propTypes.any,
  icon: propTypes.any,
};
