import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

export default function Button({
  icon,
  children,
  fullWidth,
  bgColor,
  ...props
}) {
  const IconStyle = styled.div`
    ${props => (icon ? 'margin-right: .6em;' : null)}
  `;

  const ButtonStyle = styled.button`
    ${props => (fullWidth === true ? 'width: 100%' : null)}
    background-color: ${props => (bgColor ? bgColor : '#da6c8c')};
    box-shadow: 0px 2px 3px rgba(199, 199, 199, 0.25);
    border: none;
    border-radius: 3px;
    color: #ffffff;
    font-size: 1em;
    padding: 0.6em 1.2em;
    margin: 0.8em 0;
    cursor: pointer;
    font-family: Muli;
    display: flex;
    flex-direction: row;
    justify-content: center;
  `;

  return (
    <ButtonStyle {...props}>
      {icon ? <IconStyle>{icon}</IconStyle> : null}
      {children}
    </ButtonStyle>
  );
}

Button.propTypes = {
  children: propTypes.any,
  icon: propTypes.any,
};
