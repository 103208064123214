import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

export default function Paginate(props) {
  const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1em;
  `;

  const { currentPage, pageLimit } = props;

  return (
    <Pagination>
      <FaArrowCircleLeft />
      <div>
        <strong>{currentPage}</strong>/{pageLimit}
      </div>
      <FaArrowCircleRight />
    </Pagination>
  );
}

Paginate.propTypes = {
  currentPage: propTypes.number,
  pageLimit: propTypes.number,
};
