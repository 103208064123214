import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import ListItem from './ListItem';

const ListStyle = styled.ul`
  list-style-type: none;
  padding: 0;
  border: 1px solid #eeeeee;
`;

export default function List(props) {
  const { items } = props;

  return (
    <ListStyle>
      {items.map((item,index) => (
        <ListItem key={item.item.id || index} icon={item.icon}>
          <a href={item.link}>{item.item}</a>
        </ListItem>
      ))}
    </ListStyle>
  );
}

List.propTypes = {
  items: propTypes.any,
};
