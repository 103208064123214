import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const FormStyle = styled.form`
  margin: 0;
`;

export default function Form({ children }) {
  return <FormStyle>{children}</FormStyle>;
}

Form.propTypes = {
  children: propTypes.any,
};
