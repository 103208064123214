import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  :hover {
    cursor: pointer;
  }
`;

const Path = styled.path`
  fill: ${({ fill }) => fill};
  transition: 200ms;

  ${SVG}:hover & {
    fill: ${({ hoverFill }) => hoverFill};
    transition: 200ms;
  }
`;

export default props => {
  const { size = '15px', fill = '#B0B9CC', hoverFill = '#B0B9CC' } = props;
  return (
    <SVG viewBox="0 0 52 52" height={size} width={size}>
      <Path
        fill={fill}
        hoverFill={hoverFill}
        d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M38.5,28H28v11c0,1.104-0.896,2-2,2
	s-2-0.896-2-2V28H13.5c-1.104,0-2-0.896-2-2s0.896-2,2-2H24V14c0-1.104,0.896-2,2-2s2,0.896,2,2v10h10.5c1.104,0,2,0.896,2,2
	S39.604,28,38.5,28z"
      />
    </SVG>
  );
};
