import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Plus from "../svgs/plus";

export const Form = styled.form`
  display: flex;
  border: 1px solid #eeeeee;
`;

export const SubmitButton = styled.button`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const Input = styled.input`
  color: #7a7a7a;
  width: 100%;
  border: none;
`;

export default function SmallInput(props) {
  const { handleSubmit, dataTestId, values, handleChange, placeholder } = props;

  return (
    <Form data-test-id={dataTestId} onSubmit={handleSubmit}>
      <SubmitButton type="submit">
        <Plus size={'15px'} hoverFill={'#DA6C8C'} />
      </SubmitButton>
      <Input
        type="text"
        name="inputValue"
        value={(values ? values.inputValue : null)}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Form>
  );
}
