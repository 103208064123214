import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const InputStyle = styled.input`
  box-sizing: border-box;
  background-color: #eeeeee;
  box-shadow: 0px 2px 3px rgba(199, 199, 199, 0.25);
  border: 1px solid #cccccc50;
  border-radius: 3px;
  color: #222222;
  margin: 0.8em 0;
  font-size: 1em;
  padding: 0.4em;
  text-indent: .2em;
  font-family: Muli;
`;

export default function Input(props) {
  return <InputStyle {...props} />;
}

Input.propTypes = {
  props: propTypes.any,
};
