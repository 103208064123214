import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const CardWrapper = styled.div`
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(219, 219, 219, 0.25);
  border: 1px solid #eeeeee;
  border-radius: 6px;
  margin: 1em;
  padding: 0.6em;

  h1 {
    font-size: 1.4em;
    color: #222222;
    margin: 0;
    padding: 0.6em 0.4em;
  }

  p {
    font-size: 1em;
    margin: 0;
    color: #363636;
    padding: 0.6em 0.4em;
  }
`;

export default function Card(props) {
  const { children } = props;
  return <CardWrapper>{children}</CardWrapper>;
}

Card.propTypes = {
  children: propTypes.any,
};
