import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';

export default function MessageItem(props) {
  const { messageType, children, avatarImagePath, userColor, botColor, botText, userText } = props;

  const MessageAvatarStyle = styled.img`
    float: right;
    width: 3vw;
    @media (max-width: 940px) {
      width: 5vw;
    }
    @media (max-width: 500px) {
      width: 7vw;
    }
    @media (max-width: 370px) {
      width: 10vw;
    }
    @media (max-width: 425px) {
      margin-bottom: 1em;
      margin-left: 0;
    }
    height: auto;
    ${props => messageType === 'user' && css`
      margin-left: 1.4em;  
    `}
    border-radius: 100%;
    ${props =>
      messageType === 'bot' &&
      css`
        float: left;
        margin-right: 1.4em;
      `}
  `;

  const MessageItemStyles = styled.li`
    float: right;
    width: 40%;
    background-color: ${props => userColor ? userColor : '#333333'};
    border-radius: 4px;
    position: relative;
    border-radius: 0.4em;
    word-wrap: break-word;

    p {
      padding: 0.6em;
      ${props => messageType === 'user' ? `color: ${props => userText ? userText : '#f9f9f9'}` : null}
      margin: 0;
    }

    h2 {
      margin: 0;
      padding: 0.8em;

      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:not(:last-of-type) {
      margin-bottom: 1em;
    }

    small {
      display: block;
      padding: 0.6em;
      color: #fff;

      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    h2,
    small {
      background-color: #333;
      color: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: ${props => userColor ? userColor : '#333333'};
      border-right: 0;
      border-bottom: 0;
      margin-top: -10px;
      margin-right: -20px;
    }

    ${props =>
      messageType === 'bot' &&
      css`
        float: left;
        background-color: ${props => botColor ? botColor : 'purple'};
        color: ${props => botText ? botText : '#505050'} !important;

        h2,
        small {
          background-color: #9867fa;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 0;
          height: 0;
          border: 20px solid transparent;
          border-right-color: ${props => botColor ? botColor : 'purple'};
          border-left: 0;
          border-bottom: 0;
          margin-top: -10px;
          margin-left: -20px;
        }
      `}

    @media(max-width: 1100px) {
      width: 60%;
    }
    @media (max-width: 425px) {
      width: 80%;
    }
  `;
  const MessageWrapper = styled.div`
    &:not(:last-of-type) {
      margin: 0.6em 0;
    }
  `;
  return (
    <MessageWrapper>
      {avatarImagePath ? (
        <MessageAvatarStyle
          alt="Avatar"
          src={avatarImagePath}
          messageType={messageType}
        />
      ) : null}
      <MessageItemStyles key={props.id}>{children}</MessageItemStyles>
    </MessageWrapper>
  );
}

MessageItem.propTypes = {
  messageType: propTypes.string,
  children: propTypes.any,
  avatarImagePath: propTypes.string,
};
